import * as _callBound2 from "call-bind/callBound";

var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;

import * as _shams2 from "has-symbols/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var callBound = _callBound;

var hasSymbols = _shams();

var hasToStringTag = hasSymbols && !!Symbol.toStringTag;
var has;
var $exec;
var isRegexMarker;
var badStringifier;

if (hasToStringTag) {
  has = callBound("Object.prototype.hasOwnProperty");
  $exec = callBound("RegExp.prototype.exec");
  isRegexMarker = {};

  var throwRegexMarker = function () {
    throw isRegexMarker;
  };

  badStringifier = {
    toString: throwRegexMarker,
    valueOf: throwRegexMarker
  };

  if (typeof Symbol.toPrimitive === "symbol") {
    badStringifier[Symbol.toPrimitive] = throwRegexMarker;
  }
}

var $toString = callBound("Object.prototype.toString");
var gOPD = Object.getOwnPropertyDescriptor;
var regexClass = "[object RegExp]";
exports = hasToStringTag // eslint-disable-next-line consistent-return
? function isRegex(value) {
  if (!value || typeof value !== "object") {
    return false;
  }

  var descriptor = gOPD(value, "lastIndex");
  var hasLastIndexDataProperty = descriptor && has(descriptor, "value");

  if (!hasLastIndexDataProperty) {
    return false;
  }

  try {
    $exec(value, badStringifier);
  } catch (e) {
    return e === isRegexMarker;
  }
} : function isRegex(value) {
  // In older browsers, typeof regex incorrectly returns 'function'
  if (!value || typeof value !== "object" && typeof value !== "function") {
    return false;
  }

  return $toString(value) === regexClass;
};
export default exports;